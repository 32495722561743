import store from "../store";

export default function auth({ next, router }) {
  if (!store.getters.isAuthenticated) {
    return router.push("/login");
  }
  store
    .dispatch("refreshToken")
    .then(() => {
      return next();
    })
    .catch(() => {
      store.dispatch("logout");
      return router.push("/login");
    });
}
