<template>
  <v-app :class="{ app: true, electron: isInsideElectron }">
    <v-app-bar app clipped-left color="primary" dark class="app-bar" dense>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="
            $vuetify.breakpoint.smAndDown &&
              isInsideDashboard &&
              isAuthenticated
          "
        ></v-app-bar-nav-icon>
        <router-link to="/" class="app-title">
          <img src="./assets/lextimer.png" alt="Timer Logo" />Timer
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        class="mr-2"
        to="/registrieren"
        text
        v-if="!isInsideDashboard && !isAuthenticated"
      >
        Registrieren
      </v-btn>
      <v-btn
        to="/login"
        text
        v-if="!isInsideDashboard && !isAuthenticated"
        class="mr-2"
      >
        Login
      </v-btn>
      <v-tooltip bottom v-if="!isInsideElectron">
        <template v-slot:activator="{ on }">
          <v-btn
            href="http://app.timer.webentwickler.site/Timer-Setup.exe"
            v-on="on"
            icon
          >
            <v-icon small>get_app</v-icon>
          </v-btn>
        </template>
        <span>Download Windows-App</span>
      </v-tooltip>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon small>help</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/impressum">
            <v-list-item-title>Impressum</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item to="/datenschutz">
            <v-list-item-title>Datenschutz</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom v-if="isInsideDashboard">
        <template v-slot:activator="{ on }">
          <v-btn to="/einstellungen" v-on="on" icon>
            <v-icon small>settings</v-icon>
          </v-btn>
        </template>
        <span>Einstellungen</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isInsideDashboard">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" to="/logout" icon>
            <v-icon small>logout</v-icon>
          </v-btn>
        </template>
        <span>Abmelden</span>
      </v-tooltip>
      <template v-if="isInsideElectron">
        <v-divider vertical></v-divider>
        <v-btn v-if="isInsideElectron" icon @click="electronWindow.hide()">
          <v-icon>minimize</v-icon>
        </v-btn>
        <v-btn
          v-if="isInsideElectron"
          icon
          @click="
            electronWindow.isMaximized()
              ? electronWindow.unmaximize()
              : electronWindow.maximize()
          "
        >
          <v-icon>web_asset</v-icon>
        </v-btn>
        <v-btn v-if="isInsideElectron" icon @click="electronWindow.close()">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-system-bar dark color="primary">
      <v-spacer></v-spacer>
      <v-icon>mdi-wifi-strength-4</v-icon>
      <v-icon>mdi-signal-cellular-outline</v-icon>
      <v-icon>mdi-battery</v-icon>
      <span>12:30</span>
    </v-system-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="isInsideDashboard && isAuthenticated"
      clipped
      :permanent="$vuetify.breakpoint.mdAndUp"
    >
      <v-list dense nav>
        <v-list-item link to="/aufzeichnungen">
          <v-list-item-icon>
            <v-icon>timer</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Aufzeichnungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/statistiken">
          <v-list-item-icon>
            <v-icon>insights</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Statistiken</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link to="/kunden">
          <v-list-item-icon>
            <v-icon>people</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Kunden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/taetigkeiten">
          <v-list-item-icon>
            <v-icon>add_task</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Tätigkeiten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link to="/rechnungen">
          <v-list-item-icon>
            <v-icon>receipt</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Rechnungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link @click="exportData">
          <v-list-item-icon>
            <v-icon>file_download</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Export</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="main">
      <Notifications></Notifications>
      <Player v-if="isInsideDashboard && isAuthenticated"></Player>
      <div class="content">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
.app-title {
  display: flex;

  img {
    height: 1.5em;
    margin-right: 0.5em;
  }
}
body .app {
  background-color: #f5f5f5;

  ::v-deep .app-bar {
    -webkit-user-select: none;
    -webkit-app-region: drag;
    a {
      text-decoration: none;
    }

    .v-btn,
    a {
      -webkit-app-region: no-drag;
    }
  }
}
.content {
  margin: 2rem;

  @media (max-width: 690px) {
    margin: 0.75rem;
  }
}
.main {
  font-size: 14px;
  line-height: 1.42857143;
  color: #777;

  ::v-deep .h1,
  ::v-deep .h2,
  ::v-deep .h3,
  ::v-deep .h4,
  ::v-deep .h5,
  ::v-deep .h6,
  ::v-deep h1,
  ::v-deep h2,
  ::v-deep h3,
  ::v-deep h4,
  ::v-deep h5,
  ::v-deep h6 {
    font-family: inherit;
    font-weight: 700;
    line-height: 1.27;
    color: #333;
    margin-bottom: 2rem;
  }

  ::v-deep h1 {
    font-size: 28px;
  }

  ::v-deep .v-btn:not(.v-btn--round) {
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    padding: 9px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    box-shadow: none;

    &:not(.success).v-btn--has-bg {
      color: #777;
      border-color: #777;
      background-color: #fff;

      &.theme--dark {
        color: #fff;
      }
    }
  }
  ::v-deep .v-item-group .v-btn {
    border-radius: 0;
  }
  ::v-deep .v-text-field--solo-flat.v-select .v-input__slot {
    color: #777;
    border: 1px solid #777;
    background-color: #fff;
  }
  ::v-deep .v-card .v-progress-linear:first-child {
    position: absolute;
  }

  ::v-deep .inline-lexoffice-icon {
    height: 1em;
    margin-bottom: -0.1em;
    margin-right: 0.2em;
  }
}
.app-bar.theme--dark {
  a {
    color: #fff;
    text-decoration: none;
  }
}
</style>
<style lang="scss">
html {
  background-color: #f5f5f5;
  overflow-y: auto;

  &.electron {
    overflow: hidden;

    .v-application--wrap {
      height: 100vh;
      overflow: auto;
    }
  }
}
.v-list .v-progress-linear:first-child {
  position: absolute;
  margin-top: -8px;
}
.swal2-container {
  * {
    font-family: "Roboto", sans-serif;
  }
  .swal2-content {
    font-size: inherit;
  }
  .swal2-title {
    font-size: 1.6rem;
  }
  a {
    color: inherit;
  }
}
.editable {
  position: relative;
  select {
    cursor: pointer;
  }
  input,
  select {
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 0px 3px;

    &:focus,
    &:hover {
      &:not([disabled]) {
        border: 1px solid #868686;
      }
    }
  }
}
.table-actions {
  white-space: nowrap;
}
</style>
<script>
import FileDownload from "js-file-download";

export default {
  name: "App",

  data: () => ({
    drawer: false,
    refreshTokenInterval: null
  }),
  components: {
    Notifications: () =>
      import(
        /* webpackChunkName: "Notifications" */ "@/components/Notifications.vue"
      ),
    Player: () =>
      import(/* webpackChunkName: "Player" */ "@/components/Player.vue")
  },
  beforeDestroy() {
    clearInterval(this.refreshTokenInterval);
  },
  methods: {
    refreshToken() {
      this.$store.dispatch("refreshToken");
    },
    exportData() {
      return this.axios
        .get(`/api/v1/me/export`, {
          responseType: "blob"
        })
        .then(response => {
          FileDownload(response.data, "zeiterfassung.xlsx");
        });
    }
  },
  mounted() {
    this.refreshTokenInterval = setInterval(this.refreshToken, 900000);
    if (this.electronWindow) {
      document.documentElement.className = "electron";
    }
  },
  computed: {
    electronWindow() {
      if (this.isInsideElectron) {
        const { remote } = require("electron");
        return remote.BrowserWindow.getFocusedWindow();
      } else {
        return null;
      }
    },
    isInsideDashboard() {
      return this.$route.meta.layout === "dashboard";
    },
    isEmailVerified() {
      return this.$store.getters["profile/email_verified"];
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    user() {
      return this.$store.getters["profile/me"];
    }
  },
  watch: {
    isInsideDashboard: {
      handler(isInsideDashboard) {
        if (isInsideDashboard && this.isAuthenticated) {
          this.$store.dispatch("profile/me");
        }
      },
      immediate: true
    }
  }
};
</script>
