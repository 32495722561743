import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {middleware: guest}
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "Login" */ "../views/Login.vue")
    },
    {
        path: "/registrieren",
        name: "Register",
        component: () =>
            import(/* webpackChunkName: "Register" */ "../views/Register.vue")
    },
    {
        path: "/logout",
        name: "Logout",
        component: () =>
            import(/* webpackChunkName: "Logout" */ "../views/Logout.vue")
    },
    {
        path: "/verifizieren",
        name: "Verify",
        component: () =>
            import(/* webpackChunkName: "Verify" */ "../views/Verify.vue")
    },
    {
        path: "/aufzeichnungen",
        name: "Tracks",
        component: () =>
            import(/* webpackChunkName: "Tracks" */ "../views/Tracks.vue"),
        meta: {middleware: auth, layout: "dashboard"}
    },
    {
        path: "/einstellungen",
        name: "Settings",
        component: () =>
            import(/* webpackChunkName: "Settings" */ "../views/Settings.vue"),
        meta: {middleware: auth, layout: "dashboard"}
    },
    {
        path: "/statistiken",
        name: "Statistics",
        component: () =>
            import(/* webpackChunkName: "Statistics" */ "../views/Statistics.vue"),
        meta: {middleware: auth, layout: "dashboard"}
    },
    {
        path: "/kunden",
        name: "Customers",
        component: () =>
            import(/* webpackChunkName: "Customers" */ "../views/Customers.vue"),
        meta: {middleware: auth, layout: "dashboard"}
    },
    {
        path: "/taetigkeiten",
        name: "Tasks",
        component: () =>
            import(/* webpackChunkName: "Tasks" */ "../views/Tasks.vue"),
        meta: {middleware: auth, layout: "dashboard"}
    },
    {
        path: "/rechnungen",
        name: "Invoices",
        component: () =>
            import(/* webpackChunkName: "Invoices" */ "../views/Invoices.vue"),
        meta: {middleware: auth, layout: "dashboard"}
    },
    {
        path: "/datenschutz",
        name: "DataPrivacy",
        component: () =>
            import(/* webpackChunkName: "DataPrivacy" */ "../views/DataPrivacy.vue")
    },
    {
        path: "/impressum",
        name: "Imprint",
        component: () =>
            import(/* webpackChunkName: "Imprint" */ "../views/Imprint.vue")
    },
    {
        path: "/kunden/:customer_token!:customer_id/zeiten",
        name: "CustomerTasks",
        component: () =>
            import(/* webpackChunkName: "CustomerTasks" */ "../views/CustomerTasks.vue")
    }
];

export default routes;
