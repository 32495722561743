import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import profile from "./modules/profile-module";
import alerts from "./modules/alerts-module";
import customers from "./modules/customers-module";
import tasks from "./modules/tasks-module";
import tracks from "./modules/tracks-module";
import invoices from "./modules/invoices-module";
import summaries from "./modules/summaries-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    alerts,
    customers,
    tasks,
    tracks,
    invoices,
    summaries
  }
});
