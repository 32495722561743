const state = {
  list: []
};

const mutations = {
  ADD_ALERT: (state, alert) => {
    alert.id = +new Date();
    state.list = [alert, ...state.list];
  },
  REMOVE_LAST_ALERT: state => {
    state.list.shift();
  },
  REMOVE_ALERT: (state, alert) => {
    state.list = state.list.filter(a => a.id !== alert.id);
  }
};

const actions = {
  remove_alert({ commit }, alert) {
    commit("REMOVE_ALERT", alert);
  },
  success({ commit }, message) {
    commit("ADD_ALERT", {
      message: message,
      icon: "add_alert",
      type: "success",
      timeout: 8000
    });
    setTimeout(() => {
      commit("REMOVE_LAST_ALERT");
    }, 8000);
  },
  error({ commit }, message) {
    commit("ADD_ALERT", {
      message: message,
      icon: "add_alert",
      type: "error",
      timeout: 8000
    });
    setTimeout(() => {
      commit("REMOVE_LAST_ALERT");
    }, 8000);
  }
};

const getters = {
  list: state => state.list
};

const alerts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default alerts;
