import service from "@/store/services/tracks-service";

const state = {
  list: [],
  track: {},
  current_track: {},
  meta: { page: { total: 0 } },
  url: null
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, track) => {
    state.track = track;
  },
  SET_CURRENT_RESOURCE: (state, track) => {
    state.current_track = track;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  }
};

const actions = {
  list({ commit }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit }, params) {
    return service.get(params).then(track => {
      commit("SET_RESOURCE", track);
    });
  },

  getCurrent({ commit }) {
    return service
      .list({ filter: { current: true }, include: "task,customer" })
      .then(({ list }) => {
        if (list.length) {
          commit("SET_CURRENT_RESOURCE", list[0]);
        }
      });
  },

  add({ commit }, params) {
    return service.add(params).then(track => {
      commit("SET_RESOURCE", track);
      if (!track.end) {
        commit("SET_CURRENT_RESOURCE", track);
      }
    });
  },

  update({ commit }, params) {
    return service.update(params).then(track => {
      commit("SET_RESOURCE", track);
    });
  },

  destroy(methods, params) {
    return service.destroy(params);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  track: state => state.track,
  current: state => state.current_track,
  url: state => state.url
};

const tracks = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default tracks;
