import Vue from "vue";
import qs from "qs";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL + "/api/v1";
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return Vue.prototype.axios.get(`${url}/customers`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios
    .get(`${url}/customers/${id}`, options)
    .then(response => {
      let customer = jsona.deserialize(response.data);
      delete customer.links;
      return customer;
    });
}

function add(customer) {
  const payload = jsona.serialize({
    stuff: customer,
    includeNames: null
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios
    .post(`${url}/customers`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(customer) {
  const payload = jsona.serialize({
    stuff: customer,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios
    .patch(`${url}/customers/${customer.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function sync() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios
    .post(`${url}/customers/sync`, {}, options)
    .then(response => {
      return response.data.status;
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios.delete(`${url}/customers/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  sync
};
