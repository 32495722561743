import Vue from "vue";

export default {
  state: {
    isAuthenticated: localStorage.getItem("access_token") !== null
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    }
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    }
  },

  actions: {
    login(context, payload) {
      return new Promise((resolve, reject) => {
        Vue.prototype.axios
          .post("/oauth/token", {
            grant_type: "password",
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            ...payload
          })
          .then(resp => {
            if (resp.data.access_token) {
              localStorage.setItem("access_token", resp.data.access_token);
              localStorage.setItem("refresh_token", resp.data.refresh_token);
              localStorage.setItem(
                "token_expiration",
                new Date().getTime() / 1000 + resp.data.expires_in
              );
              context.commit("isAuthenticated", {
                isAuthenticated: true
              });
              resolve(resp);
            } else {
              reject(resp);
            }
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    register(context, payload) {
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json"
        }
      };
      return new Promise((resolve, reject) => {
        Vue.prototype.axios
          .post("/api/v1/register", payload, options)
          .then(resp => {
            resolve(resp);
          })
          .catch(e => {
            reject(e);
          });
      });
    },

    refreshToken({ dispatch }, force = false) {
      const refresh_token = localStorage.getItem("refresh_token");
      const token_expiration = localStorage.getItem("token_expiration");
      if (
        refresh_token &&
        (token_expiration < new Date().getTime() / 1000 + 900 || force)
      ) {
        return dispatch("login", {
          grant_type: "refresh_token",
          refresh_token
        });
      } else {
        return Promise.resolve();
      }
    },

    logout(context) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("token_expiration");
      localStorage.removeItem("refresh_token");
      context.commit("isAuthenticated", {
        isAuthenticated: false
      });
    }
  }
};
