import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { dateFilter } from "vue-date-fns";
import VueDateFns from "vue-date-fns";

Vue.filter("date", dateFilter);
Vue.use(VueDateFns);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn:
      "https://35da66c621aa4113a25127cc9ae67b5d@o444002.ingest.sentry.io/5647121",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1
  });
}

const options = {
  confirmButtonColor: "#0e9e57",
  cancelButtonColor: "#FF5252"
};
Vue.use(VueSweetalert2, options);

Vue.config.productionTip = false;
Vue.mixin({
  computed: {
    isInsideElectron() {
      var userAgent = navigator.userAgent.toLowerCase();
      return userAgent.indexOf(" electron/") > -1;
    }
  },
  methods: {
    openUrl(url) {
      if (this.isInsideElectron) {
        const ipcRenderer = require("electron").ipcRenderer;
        ipcRenderer.send("open-url", url);
      } else {
        const win = window.open(url, "_blank");
        win.focus();
      }
    },
    formatSeconds(s) {
      let seconds = s;
      s = Math.abs(s);

      let t = [0, 0, 0];
      let r = s % 3600;

      t[0] = Math.floor(s / 3600);
      t[1] = Math.floor(r / 60);
      r = r % 60;
      t[2] = r;

      return (
        (seconds < 0 ? "-" : "") +
        (t[0] < 10 ? "0" : "") +
        t[0] +
        ":" +
        (t[1] < 10 ? "0" + t[1] : t[1]) +
        ":" +
        (t[2] < 10 ? "0" + t[2] : t[2])
      );
    },
    formatHours(hours) {
      const seconds = hours * 60 * 60;
      return this.formatSeconds(seconds);
    },
    formatEarnings(earnings, decimal = 2) {
      if (!earnings) {
        earnings = 0;
      }
      return earnings
        .toFixed(decimal)
        .replace(".", ",")
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    debounce(fn, delay = 300) {
      return ((...args) => {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          this.timerId = null;
          fn(...args);
        }, delay);
      })();
    }
  }
});

const app = new Vue({
  router,
  store,
  vuetify,
  axios,
  render: h => h(App),
  created() {
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      this.$router.push("/aufzeichnungen");
    }
  }
}).$mount("#app");

store.$app = app;
