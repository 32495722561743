<template>
  <div>
    <v-card class="mb-5">
      <v-card-title>
        Jetzt starten
      </v-card-title>
      <v-card-text>
        <v-btn to="/login" class="mr-3">Login</v-btn>
        <v-btn to="/registrieren" color="success">Registrieren</v-btn>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col lg="7" xs="12">
            <h1>Lexoffice Zeiterfassung</h1>
            <p>
              Mit diesem Tool können Zeiten direkt für Rechnungen in lexoffice
              erfasst werden.<br /><br />
            </p>
            <h2>Automatisierte Rechnungen</h2>
            <p>
              Mithilfe der lexoffice-Schnittstelle können Kunden direkt aus
              lexoffice genutzt werden. Rechnungen können automatisiert erstellt
              werden, dabei werden alle zuvor erfassten Tätigkeiten und
              Stundensätze berücksichtigt.
            </p>
          </v-col>
          <v-col lg="5" xs="12">
            <v-carousel
              hide-delimiters
              style="min-width: 300px"
              height="350"
              cycle
              show-arrows
            >
              <v-carousel-item
                v-for="screenshot in [
                  require('@/assets/screenshots/heute.png'),
                  require('@/assets/screenshots/statistiken.png'),
                  require('@/assets/screenshots/kunden.png'),
                  require('@/assets/screenshots/taetigkeiten.png'),
                  require('@/assets/screenshots/rechnungen.png')
                ]"
                :key="screenshot"
                :src="screenshot"
              >
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>
