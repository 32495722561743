import Vue from "vue";
import qs from "qs";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL + "/api/v1";
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return Vue.prototype.axios.get(`${url}/tracks`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios
    .get(`${url}/tracks/${id}`, options)
    .then(response => {
      let track = jsona.deserialize(response.data);
      delete track.links;
      return track;
    });
}

function add(track) {
  const payload = jsona.serialize({
    stuff: track,
    includeNames: null
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios
    .post(`${url}/tracks?include=task,customer`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(track) {
  const payload = jsona.serialize({
    stuff: track,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios
    .patch(`${url}/tracks/${track.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return Vue.prototype.axios.delete(`${url}/tracks/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy
};
