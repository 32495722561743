import service from "@/store/services/profile-service";
import router from "../../router";

const state = {
  me: null
};

const mutations = {
  SET_RESOURCE: (state, me) => {
    state.me = me;
  }
};

const actions = {
  me({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      service
        .get(params)
        .then(profile => {
          commit("SET_RESOURCE", profile.list);
          resolve(profile.list);
        })
        .catch(e => {
          if (e.response.status === 403) {
            if (router.history.current.path !== "/verifzieren") {
              router.push("/verifizieren");
            }
          }
          if (e.response.status === 401) {
            dispatch("logout");
            router.push("/login");
          }
          reject(e);
        });
    });
  },

  update({ commit }, params) {
    return service.update(params).then(profile => {
      commit("SET_RESOURCE", profile);
    });
  },

  destroy(methods, params) {
    return service.destroy(params);
  }
};

const getters = {
  me: state => state.me
};

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default profile;
