import service from "@/store/services/customers-service";

const state = {
  list: [],
  customer: {},
  meta: { page: { total: 0 } },
  url: null
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, customer) => {
    state.customer = customer;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  }
};

const actions = {
  list({ commit }, params) {
    if (!params) {
      params = {};
    }
    if (!params.filter) {
      params.filter = {};
    }
    if (!params.filter.include_archived) {
      params.filter.include_archived = 0;
    }
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit }, params) {
    return service.get(params).then(customer => {
      commit("SET_RESOURCE", customer);
    });
  },

  add({ commit }, params) {
    return service.add(params).then(customer => {
      commit("SET_RESOURCE", customer);
    });
  },

  update({ commit }, params) {
    return service.update(params).then(customer => {
      commit("SET_RESOURCE", customer);
    });
  },

  sync(methods, params) {
    return service.sync(params);
  },

  destroy(methods, params) {
    return service.destroy(params);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => (state.meta ? state.meta.page.total : 0),
  customer: state => state.customer,
  url: state => state.url
};

const customers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default customers;
